<script setup lang="ts">
const props = defineProps<{
  content: StoryblokRichtextContent;
}>();

const renderedRichtext = computed(() => {
  let renderedContent = renderRichText(props.content);

  const urlsToFix = renderedContent.match(/href=(["'])(.+)\/\1/g) || [];

  for (const toBeReplaced of urlsToFix) {
    // remove trailing slashes from links
    renderedContent = renderedContent.replaceAll(
      toBeReplaced,
      toBeReplaced.slice(0, -2) + '"'
    );
  }

  return renderedContent;
});
</script>

<template>
  <div class="prose lg:prose-lg" v-html="renderedRichtext"></div>
</template>
